<template>
  <el-dialog class="pay_result_dialog" :visible.sync="dialogVisible" @close="hide" width="40%" :close-on-click-modal="false">
    <div :class="['pay_result_title', result == 'success' ? 'success' : 'fail']">支付{{result == 'success' ? '成功' : '失败'}}</div>
    <div class="pay_result_tip" v-if="result == 'success'">您已支付成功，感谢您的支持！</div>
    <div class="pay_result_tip" v-else>如果遇到支付问题，可联系我们的客服将为您提供一对一服务</div>
    <div class="pay_result_btns">
      <span class="btn_default" @click="hide">{{result == 'success' ? '确定' : '取消'}}</span>
    </div>
  </el-dialog>
</template>
<script>
import { sleep } from "@/utils/utils.js";
export default {
  data() {
    return {
      dialogVisible: false,
      result: '',
    };
  },
  methods:{
    async hide() {
      sleep(500)
      this.dialogVisible=false
      // if (this.$route.path === '/formal/myOrder') {
      //   this.$bus.$emit('updateOrderList')
      // }
      window.location.reload()
    }
  },
  mounted() {
    this.$bus.$on('showPayResult', data => {
      this.dialogVisible=true
      if(data.status==2) {
        this.result = 'success'
        localStorage.removeItem('fansAttractOutdated')
      } else {
        this.result = 'fail'
      }
    })
  },
  beforeDestroy () {
    this.$bus.$off('showPayResult');
  },
};
</script>
<style lang="less" scoped>
.pay_result_dialog {
  .pay_result_title {
    font-size: 24px;
  }
  .pay_result_title.fail {
    color: #e23d16;
  }
  .pay_result_title.success {
    color: #13ba26;
  }
  .pay_result_tip {
    margin-top: 24px;
    font-size: 14px;
    color: #8d8da7;
  }
  .pay_result_btns {
    text-align: right;
    margin-top: 160px;
    span {
      display: inline-block;
      width: 140px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 6px;
      margin-left: 60px;
    }
    .btn_default {
      background: #ffffff;
      border: 1px solid #ff9226;
      color: #ff9226;
      cursor: pointer;
    }
    .btn_primary {
      background: #ff9226;
      box-shadow: 0px 3px 6px rgba(255, 146, 38, 0.4);
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>