<template>
    <el-dialog
        :visible.sync="visible"
        title="支付中心"
        :close-on-click-modal="false"
        width="54vw"
        top="10vh"
        @close="close"
    >
        <div class="create_content">
            <el-form label-width="94px">
                <el-form-item label="方案">
                    <el-radio-group v-model="product">
                        <el-radio-button :label="item.productId" v-for="(item, index) in productArr" :key="index">
                            <div>
                                <p>{{ item.description }}</p>
                                <h3>
                                    <span>{{ item.price / 100 }}</span>元/年
                                </h3>
                                <ul>
                                    <li v-show="item.productId === 'DT_LIVE_0002'" style="marginBottom: 4px">
                                        <span class="doc"></span><div>软件服务</div>
                                    </li>
                                    <li v-show="item.productId === 'DT_LIVE_0002'" style="marginBottom: 4px">
                                        <span class="doc"></span><div>直播专用电脑</div>
                                    </li>
                                    <li v-show="item.productId === 'DT_LIVE_0002'">
                                        <span class="doc"></span><div>直播专用设备（绿幕、麦克风、高清摄像头）</div>
                                    </li>
                                    <li v-show="item.productId !== 'DT_LIVE_0002'">
                                        <span class="doc"></span>
                                        <div>软件服务（100+虚拟背景，30+数图，50+大类工具，每日聚合海量资讯，全关键词覆盖图片，自定义上传素材等）</div>
                                    </li>
                                </ul>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <div class="payButton">
                    <span>支付方式</span>
                    <div>
                        <el-radio-group v-model="payPlanMode">
                            <el-radio-button label="wx">
                                <div :style="{ background: payPlanMode === 'wx' ? '#2BA145' : ''}">
                                    <img :src="payPlanMode === 'wx' ? '../../../../../assets/images/order/wx.svg' : '../../../../../assets/images/order/wx_no.svg'" alt="">
                                    <span>微信支付</span>
                                </div>
                            </el-radio-button>
                            <el-radio-button label="ali">
                                <div :style="{ background: payPlanMode === 'ali' ? '#2354F4' : ''}">
                                    <img :src="payPlanMode === 'ali' ? '../../../../../assets/images/order/zfb.svg' : '../../../../../assets/images/order/zfb_no.svg'" alt="">
                                    <span>支付宝支付</span>
                                </div>
                            </el-radio-button>
                        </el-radio-group>
                        <p>
                            <i :class="['icon_check', { checked: payPlanProtoChecked }]" @click="clickPayPlan"></i>
                            阅读并接受
                            <span @click="goProtocol">《灯云直播间购买协议》</span>
                            请确定是否购买本产品服务，本产品为软件服务，购买后生效，原则上不接受退货换货
                        </p>
                        <span class="pay_plan_nocheck" v-if="payPlanVisible">请勾选协议</span>
                    </div>
                </div>
                <div class="contactWay" v-if="product === 'DT_LIVE_0002'">
                    <div>
                        <span>收货姓名</span>
                        <el-input v-model="name" placeholder="请输入联系人（必填）"></el-input>
                    </div>
                    <div>
                        <span>联系电话</span>
                        <el-input v-model="tel" placeholder="请输入联系电话（必填）"></el-input>
                    </div>
                    <div>
                        <span>收货地址</span>
                        <el-input
                            type="textarea"
                            placeholder="请输入收货地址（必填）"
                            v-model="address"
                            :rows="4"
                        />
                    </div>
                </div>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <div>
                <span>实际支付：</span>
                <h2>{{ `¥${price / 100}`}}</h2>
            </div>
            <el-button @click="submit" type="primary" :loading="isLoading">去支付</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { getCookie } from "@/utils/utils.js";
export default {
    data: function() {
        return {
            visible: false,
            payPlanProtoChecked: false,
            payPlanVisible: false,
            isLoading: false,
            productArr: [], // 产品列表
            product: '', // 产品ID
            tel: '',
            name: '',
            address: '',
            payPlanMode: 'wx',
        }
    },
    mounted() {
        this.$bus.$on('changePay', (value = true) => {
            if (value) {
                this.open()
            } else {
                this.close()
            }
        })
    },
    beforeDestroy () {
        this.$bus.$off('changePay');
    },
    computed: {
        price() {
            if (this.product) {
                return this.productArr.filter(item => item.productId === this.product)[0].price
            } else {
                return ''
            }
        },
        payPlanDesc() {
            if (this.product) {
                return this.productArr.filter(item => item.productId === this.product)[0].description
            } else {
                return ''
            }
        }
    },
    methods: {
        goProtocol() {
            window.open('/protocol')
        },
        // 获取产品信息
        getProducts() {
            this.API.wxpay.getProduct().then((res) => {
                if (res && res instanceof Object && Array.isArray(res.data)) {
                    this.productArr = res.data.filter((item) => {
                        return ['test'].indexOf(item.productConsumer) === -1
                    })
                    this.product = this.productArr[0] ? this.productArr[0].productId : ''
                }
            })
        },
        // 获取收货人信息
        getApply() {
            this.API.user.getApply().then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.tel = res.data.mobileNumber,
                    this.name = res.data.userName,
                    this.address = res.data.companyAddress || ''
                }
            })
        },
        updateApply() {
            this.API.user.updateApply({
                userId: JSON.parse(getCookie('user')).userId,
                userName: this.name,
                mobileNumber: this.tel,
                companyAddress: this.address,
            })
        },
        open() {
            this.visible = true
            this.getProducts()
            this.getApply()
            
        },
        close() {
            Object.assign(this.$data, this.$options.data())
        },
        clickPayPlan() {
            this.payPlanProtoChecked = !this.payPlanProtoChecked
            this.payPlanVisible = false
        },
        async submit() {
            if (this.payPlanProtoChecked) {
                if (this.product === 'DT_LIVE_0002') {
                    if (!this.name) {
                        return this.$message.warning('请输入联系人！')
                    }
                    if (!this.tel) {
                        return this.$message.warning('请输入联系电话！')
                    }
                    if (!this.address) {
                        return this.$message.warning('请输入收货地址！')
                    }
                    this.updateApply()
                }
                this.getPayQrcode()
            } else {
                this.payPlanVisible = true
            }
        },
        //生成支付二维码
        async getPayQrcode() {
            this.isLoading = true;
            let data;
            const params = {
                productId: this.product,
                userId: JSON.parse(getCookie('user')).userId,
                corpId: `dengyun_${JSON.parse(getCookie('user')).userId}`,
            };

            if (this.payPlanMode == "wx") {
                data = await this.API.wxpay.dtWxPay(params);
            } else if (this.payPlanMode == "ali") {
                data = await this.API.alipay.dtAliPay(params);
            }
            this.isLoading = false;
            const orderData = {
                code_url: data.data.code_url || data.data.qr_code,
                orderId: data.data.orderId,
                payPlanMode: this.payPlanMode,
                payPlanId: this.product,
                payPlanPrice: this.price,
                payPlanDesc: this.payPlanDesc
            };
            this.$bus.$emit("genPayCode", orderData);
        },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    background: rgb(32,32,32);
}
/deep/ .el-dialog__header {
    display: flex;
    justify-Content: center;
    align-items: center;
    .el-dialog__title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
    }
}
/deep/ .el-dialog__body {
    background: #121212;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin: 8px 20px 0px;
  .el-form-item {
        .el-form-item__label {
            color: #fff;
            font-size: 16px;
            text-align: left;
            line-height: unset;
        }
        .el-radio-group {
            display: flex;
            > label {
                width: 50%;
                &:first-child {
                    margin-right: 40px;
                }
            }
            .el-radio-button__inner {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #fff;
                border-radius: 6px;
                border: 0;
                padding: 0;
                height: 154px;
                overflow: hidden;
                white-space: unset;
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    width: 100%;
                    > p {
                        font-weight: bold;
                        font-size: 14px;
                        margin-top: 16px;
                    }
                    > h3 {
                        font-size: 24px;
                        margin: 14px 0;
                        > span {
                            color: #FA6602;
                        }
                    }
                    > ul {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        background: #FEE8C0;
                        padding: 6px 0;
                        > li {
                            margin: 0 12px 0 18px;
                            display: flex;
                            color: #AB7724;
                            .doc {
                                width: 6px;
                                height: 6px;
                                background: #AB7724;
                                border-radius: 50%;
                                margin-top: 6px;
                                margin-right: 10px;
                            }
                            > div {
                                max-width: 95%;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
            .el-radio-button__inner:hover {
                color: #222;
            }
            .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                box-shadow: 0 0 0 2px #FA6602 !important;
                position: relative;
                color: #222;
                &::after {
                    content: " ";
                    right: -3px;
                    top: -3px;
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    background: url('../../../../../assets/images/order/select.svg');
                    background-size: contain;
                }
            }
        }
  }
  .contactWay {
        border-bottom: 2px solid #252525;
        padding: 30px 0 10px;
        > div {
            display: flex;
            > span {
                width: 94px;
                color: #fff;
            }
            .el-input__inner, .el-textarea__inner {
                background: #2A2A2D;
                border: 1px solid #2A2A2D;
                color: #fff;
                margin-bottom: 20px;
            }
        }
  }
  .payButton {
      display: flex;
      padding-bottom: 20px;
      border-bottom: 2px solid #252525;
      > span {
          width: 94px;
          color: #fff;
      }
      > div {
          flex: 1;
          .el-radio-group {
            > label {
                margin-right: 40px;
                .el-radio-button__inner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: unset;
                    border: 0;
                    padding: 0;
                    > div {
                        display: flex;
                        align-items: center;
                        padding: 14px 80px;
                        background: rgba(0, 0, 0, 0.5);
                        border: 1px solid rgba(255, 255, 255, 0.21);
                        border-radius: 4px;
                        > img {
                            height: 16px;
                            margin-right: 12px;
                        }
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
            .el-radio-button__orig-radio:checked+.el-radio-button__inner {
                box-shadow: unset
            }
        }
        > p {
            margin-top: 20px;
            .icon_check {
                display        : inline-block;
                width          : 15px;
                height         : 15px;
                background     : url('../../../../../assets/images/order/ico_check_off.png') center center no-repeat;
                background-size: 12px auto;
                cursor         : pointer;
                vertical-align : middle;
                margin-right   : 4px;
                position       : relative;
                top            : -1px;
            }

            .icon_check.checked {
                background     : url('../../../../../assets/images/order/ico_check_on.png') center center no-repeat;
                background-size: 12px auto;
            }
            span {
                color: #4E8CFF;
                cursor: pointer;
            }
        }
        .pay_plan_nocheck {
            color: red;
        }
      }
  }
}
/deep/ .el-dialog__footer {
    padding: 0px 20px 20px;
    .dialog-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #121212;
        padding: 0 20px 20px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        > div {
            color: #fff;
            display: flex;
            align-items: center;
            > h2 {
                font-size: 28px;
                color: #E23D16;
                margin-left: 8px;
            }
        }
    }
    .el-button--primary {
        border: 1px solid #FA6602;
        background: #FA6602;
        padding: 12px 40px
    }
}
</style>