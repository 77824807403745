<!-- 外层 -->
<template>
  <div class="layout-wrapper">
    <Header></Header>
    <router-view />
    <PayCenter />
    <PayDialog />
    <PayResultDialog />
  </div>
</template>

<script>
import { setRem, getCookie } from "@/utils/utils.js";
import Header from './components/header'
import Footer from './components/footer'
import PayCenter from './components/payCenter'
import PayDialog from './components/payCenter/PayDialog.vue'
import PayResultDialog from './components/payCenter/PayResultDialog.vue'
import ReconnectingWebSocket from 'reconnecting-websocket';
export default {
  components: {
    Header,
    Footer,
    PayCenter,
    PayDialog,
    PayResultDialog
  },
  created() {
    this.queryAuthInfo()
    this.createWebSocket()
  },
  // 适配分辨率
  mounted() {
    setRem(1920)
  },
  methods: {
    // 获取当前用户权限相关信息
    queryAuthInfo() {
      this.API.wxpay.queryOutUserAuthInfo({
        corpId: `dengyun_${JSON.parse(getCookie('user')).userId}`,
        productType: 5,
        userId: JSON.parse(getCookie('user')).userId,
      }).then((res) => {
        if (res && res instanceof Object && res.code === 0) {
          localStorage.setItem('userAuthInfo', res.data ? JSON.stringify(res.data) : res.data)
        }
      })
    },
    getObsCode() {
      const obsUrl = JSON.parse(getCookie('user')).obsUrl
      const url = new URL(obsUrl)
      return url.searchParams.get('obsCode')
    },
    //连接本地obs
    createWebSocket() {
      const that = this
      const obsCode = that.getObsCode()
      const path = "wss://ws.wedengta.com/ws/livestream/"
      if ("WebSocket" in window) {
        const webSocket = new ReconnectingWebSocket(path + obsCode + "/")
        that.global.setWs(webSocket);
        webSocket.addEventListener('close', (e) => {
          console.log('websocket 关闭')
        })
        webSocket.addEventListener('error', (e) => {
           console.log('websocket 连接错误')
        })
      } else {
         console.log("您的浏览器不支持 WebSocket!");
      }
    }
  },
};
</script>

<style scoped lang="less">
/* @import "./layout.css"; */
.layout-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #16181A;
  > div:nth-child(2) {
    flex: 1;
  }
}
</style>
