<!-- 头部 -->
<template>
  <div class="header-wrap">
        <div class="header_left" @click="goHome">
            <img src="../../../../../assets/images/home/logo.png" alt="" />
            <h3>灯云直播</h3>
        </div>
        <div class="let">
            <div class="help" @click="useHelp">
                <img src="../../../../../assets/images/home/help.svg" alt="">
                <span class="hidden">
                    <span>使用帮助</span>
                </span>
            </div>
            <!-- <img src="../../../../../assets/images/home.svg" alt="" @click="goHome"> -->
            <el-popover
                placement="top-start"
                width="360"
                trigger="hover"
                popper-class="user_info_popper"
            >
                <div class="userInfo">
                    <div class="info">
                        <span>{{ mobile }}</span>
                        <span @click="payOrder">续费</span>
                    </div>
                    <h3 v-if="!authVisible">您的权限到期，请续费使用</h3>
                    <h3 v-else>
                        {{ isTest ? '会员版' : '试用版'}}至：{{ authEndDate }}
                    </h3>
                    <!-- <h3 class="copy" @click="copy(url)">
                        复制接收地址
                    </h3> -->
                    <div class="operation">
                        <div @click="jumpOrder">我的订单</div>
                        <span></span>
                        <div @click="logout">退出登录</div>
                    </div>
                </div>
                <div class="logo_header" slot="reference">
                    <img :src="logoUrl" alt="" />
                </div>
            </el-popover>
        </div>
  </div>
</template>
<script>
import { delCookie, getCookie, copyText, GET_URL_PARAMS } from "@/utils/utils.js";
import User from '../../../../../assets/images/user.svg'
export default {
  data: function() {
    return {
        logoUrl: User,
        url: '',
        mobile: "***********",
        authVisible: true, // 权限没有到期
        isTest: 0, // 0|试用 1|会员
        authEndDate: '',
    }
  },
  created() {
        try {
            let userAuthInfo = JSON.parse(localStorage.getItem('userAuthInfo'))
            if (userAuthInfo instanceof Object && (this.$moment(userAuthInfo.endDate).valueOf() > this.$moment().valueOf())) {
                this.authVisible = true
            } else {
                this.authVisible = false
            }
            const { obsCode } = GET_URL_PARAMS(JSON.parse(getCookie('user')).obsUrl)
            this.url = `${location.origin}/formal/receive?obsCode=${obsCode}`
            this.authEndDate = userAuthInfo.endDate
            this.isTest = userAuthInfo.isTest
        } catch (error) {
            console.log(error)
        }
        this.updateLogoUrl()
  },
  mounted() {
      this.$bus.$on('updateLogo', () => {
          this.updateLogoUrl()
      })
  },
  methods: {
      copy(value) {
            copyText(value).then(() => {
                this.$message({ showClose: true, type: 'success', message: `复制成功`})
            })
        },
      updateLogoUrl() {
        try {
            let user = JSON.parse(getCookie('user'))
            if (user.headUrl && user.headUrl !== 'null') {
                this.logoUrl = user.headUrl
            }
            if (user.mobileNumber && user.mobileNumber !== 'null') {
                let str = user.mobileNumber.slice(3,7)
                this.mobile = user.mobileNumber.replace(new RegExp(str), '****')
            }
        } catch (error) {
            console.log(error)
        }
      },
    async goHome() {
      this.$router.push({ path: "/formal" });
    },
    useHelp() {
        // const a = document.createElement('a');
        // a.setAttribute('href', 'https://broadcast.wedengta.com/灯云直播用户服务文档.doc');
        // a.setAttribute('download', '灯云直播用户服务文档.doc');
        // a.click();
        window.open('https://tanzhiying.feishu.cn/docs/doccnFMPlcbDIn7dq21Gj3smBGh')
    },
    jumpOrder() {
      this.$router.push({ path: "/formal/myOrder" });
    },
    logout() {
        this.$confirm('是否退出登录?', '退出提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info'
        }).then(() => {
            delCookie("token");
            delCookie("token_expire_time");
            delCookie("user");
            localStorage.clear()
            this.$router.push({ path: "/index" });
        }).catch(() => {
          console.log('取消退出登录') 
        });
    },
    payOrder() {
        this.$bus.$emit('changePay', true)
    }
  },
};
</script>
<style lang="less">
.header-wrap {
    height: 60px;
    padding: 10px 18px;
    width: 100%;
    box-sizing:border-box;
    background: #262626;
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_left {
    display: flex;
    align-items: center;
    cursor: pointer;
    > img {
        height: 36px;
        width: auto;
        margin-right: 12px;
    }
    > h3 {
        color: #fff;
        font-size: 28px;
        line-height: 1;
    }
    > button {
      margin-left: 30px;
    }
}
.let {
  display: flex;
  align-items: center;
  > img {
    height: 28px;
    width: 28px;
    margin-right: 16px;
    cursor: pointer;
  }
  .help {
      display: flex;
      align-items: center;
      color: #fff;
      margin-right: 16px;
      position: relative;
      > img {
          height: 28px;
          width: 28px;
          cursor: pointer;
          &:hover + .hidden {
              opacity: 1;
          }
      }
      .hidden {
            position: absolute;
            width: 60px;
            right: 44px;
            opacity: 0;
            background: #68696B;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            display: flex;
            align-items: center;
            padding: 3px 2px 3px 8px;
            &::after {
                content: " ";
                display: inline-block;
                position: absolute;
                right: -20px;
                border: 12px solid transparent;
                border-left: 8px solid #68696B;
            }
      }
  }
}
.userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    .info {
        margin-top: 20px;
        display: flex;
        align-items: center;
        > span:last-child {
            background: linear-gradient(90deg, #F6E0AB 0%, #EABE72 100%);
            border-radius: 16px;
            padding: 6px 16px;
            color: #5B3A00;
            margin-left: 16px;
            cursor: pointer;
        }
    }
    > h3 {
        width: 86%;
        font-size: 14px;
        color: #FFDDA1;
        padding: 10px 0;
        background: rgba(14, 14, 14, 0.25);
        border-radius: 4px;
        text-align: center;
        margin: 12px 0 0;
        box-sizing: border-box;
    }
    .copy {
        cursor: pointer;
        &:hover {
            color: #4E8CFF;
        }
    }
    .operation {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #212121;
        margin-top: 18px;
        > div {
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            width: 49%;
            &:hover {
                color: #4E8CFF;
            }
        }
        > span {
            width: 1px;
            height: 30px;
            background: #212121;
        }
    }
}
.logo_header {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: rgb(255, 177, 0);
    > img {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
