<template>
  <el-dialog
    :class="['pay_dialog', orderData.payPlanMode == 'wx' ? 'weixin' : 'ali']"
    :title="orderData.payPlanMode == 'wx' ? '微信' : '支付宝'"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    width="40%"
    :close-on-click-modal="false"
    :before-close="beforeClose"
  >
    <div class="pay_plan">{{ orderData.payPlanDesc }}</div>
    <div class="pay_info">
      <div class="">
        订单金额<br /><span class="pay_info_tip"
          >付款成功后，可提交申请开具发票</span
        >
      </div>
      <div class="pay_info_price">￥ {{ orderData.payPlanPrice / 100 }}</div>
    </div>
    <div class="pay_qrcode">
      <div id="qrcode" class="qrcode"></div>
      <div class="pay_qrcode_tip">
        请使用手机打开{{
          orderData.payPlanMode == "wx" ? "微信" : "支付宝"
        }}扫描二维码完成支付<br />
        <span>支付完成前请勿关闭弹窗</span>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import QRCode from "qrcodejs2";
import { sleep } from "@/utils/utils.js";
export default {
  data() {
    return {
      dialogVisible: false,
      orderData: {},
      isEnd: false //轮询是否停掉，当页面关闭的时候，
    };
  },
  methods: {
    beforeClose() {
      this.isEnd = true;
      this.dialogVisible = false;
    },
    createCode() {
      document.getElementById("qrcode").innerHTML = "";
      new QRCode("qrcode", {
        text: this.orderData.code_url,
        width: 180,
        height: 180,
        colorDark: "#000",
        colorLight: "#fff"
      });
    },
    async checkOrder() {
      let data;
      if (this.orderData.payPlanMode == "wx") {
        data = await this.API.wxpay.getOrderInfo(this.orderData.orderId);
      } else if (this.orderData.payPlanMode == "ali") {
        data = await this.API.alipay.getOrderInfo(this.orderData.orderId);
      }

      //等待支付
      if ([0, 1].indexOf(data.data.status) > -1) {
        await sleep(2000);
        if (!this.isEnd) {
          this.checkOrder();
        }
      } else {
        this.dialogVisible = false;
        this.$bus.$emit('changePay', false)
        this.$bus.$emit("showPayResult", data.data);
      }
    }
  },
  created() {
    this.$bus.$on("genPayCode", orderData => {
      this.dialogVisible = true;
      this.isEnd = false;
      this.orderData = Object.assign(orderData);
      this.$nextTick(() => {
        this.createCode();
        this.checkOrder();
      });
    });
  },
  beforeDestroy () {
    this.$bus.$off('genPayCode');
  },
};
</script>
<style lang="less" scoped>
.pay_dialog {
  /deep/ .el-dialog {
    border-radius: 12px;
  }

  .pay_plan {
    text-align: center;
    color: #252525;
  }
  .pay_info {
    width: 90%;
    padding: 0 20px;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9fc;
    border-radius: 12px;
    font-size: 16px;
    color: #252525;
    margin: 10px auto 0 auto;
    text-align: left;
    .pay_info_tip {
      font-size: 14px;
      color: #8d8da7;
    }
    .pay_info_price {
      color: #e23d16;
      font-size: 24px;
    }
  }
  .pay_qrcode {
    text-align: center;
    margin-top: 30px;
    .qrcode {
      position: relative;
      width: 272px;
      height: 272px;
      margin: 0 auto 18px;
      overflow: hidden;
      /deep/ img {
        width: 100%;
        height: auto;
      }
      .qrcode_icon {
        position: absolute;
        width: 48px;
        height: 48px;
        margin: auto;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    }
    .pay_qrcode_tip {
      span {
        color: #e23d16;
      }
    }
  }
}
.pay_dialog.weixin /deep/ .el-dialog__header {
  background: #2BA145;
  border-radius: 12px 12px 0px 0px;
  .el-dialog__close {
    color: #fff;
  }
}
.pay_dialog.ali /deep/ .el-dialog__header {
  background: #2354F4;
  border-radius: 12px 12px 0px 0px;
  .el-dialog__close {
    color: #fff;
  }
}
</style>
